/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LabelIcon from '@material-ui/icons/Label';
import Tab from '@material-ui/core/Tab';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Header from '../Header';
import Typography from '../Typography';
import ServiceList from './services-list';

const useStyles = makeStyles({
  root: {
    paddingBottom: '50px',
  },
  heading: {
    margin: 'auto',
  },
  header: {
    marginTop: '70px',
  },
  info: {
    padding: '50px',
    paddingBottom: '10px',
  },
  services: {
    padding: '50px',
  },
  subHeader: {
    paddingLeft: '50px',
    paddingTop: '50px',
  },
});

const data = [
  {
    key: 'gst',
    name: 'GST',
    items: [
      'Rendering of advice on applicability and levy of GST',
      'Compliance Review of the existing set up to advice on the effective systems & controls',
      'Tax audits under GST laws',
      'Advising on the applicability and assistance in procedural compliance with complete outsourcing capabilities',
      'Planning of the incidence of tax at initial stage of the transactions',
    ],
  },
  {
    key: 'taxPlanning',
    name: 'Tax Planning, management and compliance services',
    items: [
      'Tax structure that is sustainable in law',
      'Detailed analysis of tax statement based on identified contingencies and provisions.',
      'Employees orientation on basic requirements of tax laws',
      'Transaction structuring',
      'Documentations, Records & Systems integration and Compliance',
      'System set-up and training',
    ],
  },
  {
    key: 'incomeTax',
    name: 'Income Tax - Corporate & Non-corporate',
    items: [
      'Filing of income tax returns for individuals and corporates',
      'Tax Audit under Income Tax Act, 1961',
      'Providing advice on applicability of Income Tax',
      'Compliance review of Income Tax system',
      'Planning of tax incidence within applicable framework',
    ],
  },
];

const assesment = [
  'Attending assessment and appellate proceedings before various authorities under Income Tax and GST Act',
  'Filing appeal under Income Tax and GST Act',
  'End to end service from consultancy to assessment proceedings for litigation matters',
  'Filing of GST refunds and rebates before GST authorities',
  'Representaion before MCA, RBI and FEMA for matters relating to corporate laws',
];

const assessmentData = [
  {
    key: 'accountingAdvisory',
    name: 'Accounting Advisory',
    items: [
      'IFRS Accounting',
      'System study & design',
      'Supervising the Implementation of Accounting System',
      'Preparation of Accounting Manual',
    ],
  },
  {
    key: 'financialAdvisory',
    name: 'Financial Advisory',
    items: ['Financial Analysis', 'IPO Consultation'],
  },
  {
    key: 'transactionAdvisory',
    name: 'Transaction Advisory',
    items: [
      'We help our clients to successfully complete transactions, by helping them identify right potential targets/ investors, conducting due diligence, mapping out transaction roadmap and executing M&A process.',
    ],
  },
  {
    key: 'corporateAdvisory',
    name: 'Corporate Advisory',
    items: [
      'Corporate Laws',
      'Negotiable Instruments',
      'Commercial Laws',
      'Banking Laws',
    ],
  },
  {
    key: 'managementConsulting',
    name: 'Management Consulting',
    items: [
      'Corporate Planning',
      'Business Transformation',
      'Competition Analysis',
      'Market study',
      'Feasibility study',
      'Cost Optimization',
      'Performance improvement',
    ],
  },
  {
    key: 'training',
    name: 'Training',
    items: [
      'Explaining the concepts & Accounting Standards',
      'Coaching in successful implementation of accounting system',
      'Preparation of Training Module',
    ],
  },
];

const projectFunding = [
  'Project Financing',
  'Term Loan',
  'Working Capital Limits',
  'CC Limits',
  'Packing Credit',
  'Machinery Loan',
  'Mortgage Loan',
  'Private Equity / Real Estate Funding',
  'Pre- Construction Sales Strategic Tie-up',
  'Overdraft etc. ',
];

const businessPlans = [
  'Preparation of business plans, cash flow projections, budgets, and trading forecasts.',
  'Assess your finance requirements, advise on the best sources of finance , Decide on the most suitable sources of finance - personal capital, bank overdraft, long-term commercial loans and mortgages, equity capital etc.',
  'Conducting feasibility study of  projects . ',
  "Preparation and submission of financial statements i.e. CMA Data, Reports, Proposal etc  as required by businesses for arranging loans/limits – including offering necessary advice and presenting your proposals to your's chosen finance source",
];

const corporateFinance = [
  {
    key: 'corporateFinance',
    name: 'Corporate Finance',
    items: [
      'Share valuations',
      'Mergers and acquisitions',
      'Corporate restructuring.',
      'SPV Formation & Compliance',
      'Capital Structuring',
      'Financial Structuring',
      'Bridge Finance/ Debt Syndication / Infrastructure Funding',
      'Assistance in raising funds through various means like IPO/ Equity placement, institutional borrowings, capital investments and restructuring decisions.',
    ],
  },
];
export default function ServicesPage() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isSmallDevice, setSmallDevice] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpansionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setSmallDevice(window.innerWidth <= 760);
  });

  return (
    <>
      <Header />
      <Paper elevation={0} className={classes.root}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                Our Services
              </Typography>
            </Grid>
          </Grid>
        </section>
        {/* <Paper square> */}
        <Tabs
          style={{ marginTop: '30px' }}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          orientation={isSmallDevice ? 'vertical' : 'horizontal'}
          variant="fullWidth"
          scrollButtons="desktop"
          centered
        >
          <Tab label="Assurance" />
          <Tab label="Taxation" />
          <Tab label="Assessment proceedings, appeals and litigations" />
          <Tab label="Advisory" />
          <Tab label="Project funding" />
        </Tabs>
        {/* </Paper> */}
        {value === 0 && (
          <>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.info}
              style={{ textAlign: 'justify' }}
            >
              Our Assurance practice provides high quality, independent audit
              services that transcends beyond conventional financial reporting.
              We invest time and efforts to understand client’s business and
              needs and identify the key drivers, risks and opportunities. A
              comprehensive second partner review of audit engagement helps to
              assure you of your business scenario.
              <br />
              <br />
              Refined over years, we deploy robust audit methodologies and bring
              in decades of insightful experience. Our people are highly skilled
              and trained to understand the nitty-gritties of your business and
              handle complexities. We employ people development programs to stay
              abreast with key issues, global best practices and latest technical
              updates (be it Accounting Standards or government notifications).
              <br />
              <br />
              Our independent opinions, strong competency, value-addition
              approach and partnership philosophy makes us highly respected by
              our clientele, who range from PSUs, listed companies to SMEs.
            </Typography>
            <List style={{ paddingLeft: '30px' }}>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Statutory Audits" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Tax Audits under Income Tax Law" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Tax Audits under GST Law" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Internal &amp; Concurrent Audits" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="ABC Audits" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Forensic & Investigation Audits" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Compliance Audit" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Information System Audit" />
              </ListItem>
              <ListItem button>
                <LabelImportantIcon />
                <ListItemText primary="Certification Services" />
              </ListItem>
            </List>
          </>
        )}

        {value === 1 && (
          <>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.info}
              style={{ textAlign: 'justify' }}
            >
              Businesses increasingly find it difficult to keep up to the myriad
              and abstruse taxation system. With growing impact of multiple and
              ever- changing tax jurisdictions, we help our clients to identify,
              minimise and manage the incidence of such taxes within the
              framework of applicable laws. We provide a comprehensive and
              practical Tax solutions emanating from our highly specialised and
              leading Indirect and Direct Tax practices.
            </Typography>
            <ServiceList className={classes.services} data={data} />
          </>
        )}

        {value === 2 && (
          <>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.info}
              style={{ textAlign: 'justify' }}
            >
              The team brings in skill sets in representing the case by way of
              providing end-to-end solutions from replying to show cause notices
              to representing before authorities. The expertise and experience
              of the team hand holds client in achieving its objectives.
              <br />
            </Typography>
            <List style={{ paddingLeft: '30px' }}>
              {assesment.map((item) => (
                <ListItem button>
                  <LabelImportantIcon />
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {value === 3 && (
          <>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.info}
              style={{ textAlign: 'justify' }}
            >
              Our advisory and compliance services ensures that the clients
              channelizes their energy and resources in conducting their day to
              day business activities, rather than, being anxious and
              pre-occupied in statutory requirements. Our value-added offerings
              of various Advisory services including Accounting, Financial,
              Transaction, Corporate Advisory, Training and Management
              Consulting enable our clients to run their businesses ingeniously
              and benefit from astute insights generated.
            </Typography>
            <ServiceList className={classes.services} data={assessmentData} />
          </>
        )}
        {value === 4 && (
          <>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.info}
              style={{ textAlign: 'justify' }}
            >
              Finance is the life blood of any organization. We offer services
              for arranging finance for businesses to meet its various needs.
              Our range of services covers Project Funding through banks and
              other financial institutions etc.
              <br />
              <br />
              We have liaison with various banks, financial institutions and
              other funding agencies and have speciliazation in the funding
              process, as one of the co-founder is the Ex-Banker.
            </Typography>
            <br />
            <br />
            <ExpansionPanel
              key="projectFinance"
              expanded={expanded === 'projectFinance'}
              onChange={handleExpansionChange('projectFinance')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  className={classes.heading}
                  variant="h6"
                  align="center"
                >
                  Project Finance
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <List>
                  <ListItem key={1}>
                    <ListItemText
                      primary={(
                        <>
                          <LabelImportantIcon />
                          We provide Project funding advisory & arrangement of
                          the same for all types of loans which includes, but
                          not limited to:
                          <List style={{ paddingLeft: '60px' }}>
                            {projectFunding.map((item) => (
                              <ListItem button>
                                <LabelIcon />
                                <ListItemText primary={item} />
                              </ListItem>
                            ))}
                          </List>
                        </>
                      )}
                    />
                  </ListItem>
                  {businessPlans.map((item) => (
                    <ListItem button>
                      <LabelImportantIcon />
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ServiceList className={classes.services} data={corporateFinance} />
          </>
        )}
      </Paper>
    </>
  );
}
