import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { Helmet } from 'react-helmet';
import theme from '../styles/theme';
import Service from '../components/Services/detail';
import Footer from '../components/Footer';
import Logo from '../images/logo.png';

const ServicePage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Bhagat & Kumar - Chartered Accountants | Services</title>
      <meta name="title" content="Bhagat and Kumar - Chartered Accountants | Services" />
      <meta
        name="description"
        content="Bhagat & Kumar Chartered Accountants."
      />

      <meta property="og:title" content="Bhagat & Kumar Services" />
      <meta property="og:url" content={`${process.env.SITE_URL}/services`} />
      <meta
        property="og:description"
        content="Our Assurance practice provides high quality, independent audit
              services that transcends beyond conventional financial reporting.
              we invest time and efforts to understand client’s business and
              needs and identify the key drivers, risks and opportunities."
      />
      <meta property="og:image" content={Logo} />

      <meta property="twitter:card" content="Services" />
      <meta property="twitter:image" content={Logo} />
      <meta property="twitter:site" content="@bhagatandkumar" />
      <meta property="twitter:title" content="Bhagat & Kumar Services" />
      <meta
        property="twitter:description"
        content="Our Assurance practice provides high quality, independent audit
              services that transcends beyond conventional financial reporting.
              we invest time and efforts to understand client’s business and
              needs and identify the key drivers, risks and opportunities."
      />
    </Helmet>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Service />
      <Footer />
    </MuiThemeProvider>
  </>
);
export default ServicePage;
